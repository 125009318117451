/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shield': {
    width: 14,
    height: 18,
    viewBox: '0 0 14 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.083 2.008a.428.428 0 00-.166 0L2 2.978v6.5c0 1.992.976 3.822 2.55 4.856L7 15.945l2.45-1.61C11.025 13.3 12 11.47 12 9.477v-6.5l-4.917-.97zm-.235 14.037zm.304 0zm-.622-16c.31-.06.63-.06.94 0l5.724 1.13a1 1 0 01.806.98v7.323c0 2.636-1.29 5.108-3.45 6.528l-2.603 1.71a1.719 1.719 0 01-1.894 0l-2.602-1.71C1.29 14.586 0 12.114 0 9.478V2.156a1 1 0 01.807-.981L6.53.045z" _fill="#07F"/>'
  }
})
